<template>
    <div class="flexPage darkPage">
        <navbar :params="pageInfo" />
        <div class="gift">
            <img src="@/assets/img/gift.png" alt="">
        </div>

        <div class="suspend-boxes">
            <qrcode :value="inviteUrl" tag="img" class="qrcode" :options="{ size: 240 }"></qrcode>

            <div class="invitation-code">{{ $t('assets.index.invitation_code') }} U0000026x</div>
            <div class="my-link">
                <p>{{ $t('assets.index.my_links') }} 1ERM01q1248KS6jizCkGfhEc1dhkHiCsWwKS6jizCk</p>
            </div>
            <!-- 我的推广 -->
            <div class="mt16">
                <van-button @click="copy('')" round size="large" color="#3861FB">{{ $t('assets.index.copy_link')
                }}</van-button>
            </div>
        </div>
    </div>
</template>

<script>
import navbar from '@/views/modules/navbar.vue'

export default {
    data() {
        return {
            pageInfo: {
                title: '',
                border: false,
                customTitle: this.$t('assets.personal.my_promotion'),
                navbarStyle: 'normal',
                backname: "promotion",
            },
        }
    },
    components: { navbar },
    computed: {
        inviteCode() {
            return this.$mts.getUserValue('invitationCode');
        },
        inviteUrl() {
            return this.$config.domain + "/#/signup?i=" + this.inviteCode
        },
    },
    methods: {
        copy(text) { // 快速复制
            var _self = this;
            this.$copyText(text).then((e) => {
                this.$toast(this.$t('message.copy.success'))
            }, function (e) { })
        },
    }
}
</script>

<style>
.justify-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mt16 {
    margin-top: 4.0712vw;
}

.darkPage {
    background: #F8FAFD;
}

.gift {
    margin-top: 13.2316vw;
    text-align: right;
}

.gift img {
    width: 54.4529vw;
    height: 47.8372vw;
}

.suspend-boxes {
    margin: 0 3.8168vw;
    margin-top: -7.6336vw;
    padding: 0 5.0891vw 4.0712vw 5.0891vw;
    box-sizing: border-box;
    min-height: 91.6031vw;
    border-radius: 2.5445vw;
    background: #FFFFFF;
    overflow: hidden;
}

.suspend-boxes .qrcode {
    margin-top: 4.5802vw;
}

.suspend-boxes .invitation-code {
    text-align: center;
    font-family: SF Pro;
    font-size: 3.5623vw;
    font-weight: 510;
    color: #3861FB;
}

.suspend-boxes .my-link {
    margin-top: 8.1425vw;
    padding: 5.0891vw 3.0534vw 4.0712vw 3.0534vw;
    box-sizing: border-box;
    min-height: 16.285vw;
    border-radius: 2.0356vw;
    background: rgba(56, 97, 251, 0.1);
    overflow: hidden;
}

.suspend-boxes .my-link p {
    word-break: break-all;
    font-family: SF Pro;
    font-size: 3.5623vw;
    font-weight: 510;
    color: #616E85;
}
</style>